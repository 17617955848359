import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_9/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    zagorska: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-9-2-zagorska"}}) {
      body
    },
    wittek: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-9-2-wittek"}}) {
      body
    },
    gertz: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-9-2-gertz"}}) {
      body
    },
    pelczynska: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-9-2-pelczynska"}}) {
      body
    }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
